import { useParams } from 'react-router-dom'
import CategoryList from '../components/Category/CategoryList'
import ContentContainer from '../components/Layout/ContentContainer'
import { Box } from '@chakra-ui/react'

const Category = () => {
  let params: { categoryId: string } = useParams()
  let categoryId = params.categoryId

  return (
    <Box>
      <ContentContainer>
        <CategoryList categoryId={categoryId} />
      </ContentContainer>
    </Box>
  )
}

export default Category
