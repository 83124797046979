import { Tabs, TabList, Tab, Text } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import HeaderPortal from './HeaderPortal'
import useCategories from '../../hooks/use-categories'
import '../UI/HideScrollBar.module.css'
  
const CategoryTabBar = () => {
  const { loading, error, data } = useCategories()

  if(data === undefined){ return <></> }

  let headerItemsList = data && data.categories?.map(category => {
    return (
      <NavLink
        style={{
          color: 'white',
        }}
        activeStyle={{
          color: '#ed1c23',
          backgroundColor: 'white',
          borderRadius: 3,
        }}
        to={'/category/' + category.id}
        key={ category.id }
      >
        <Tab>
          <Text textTransform='capitalize' fontWeight='bold'>
            {category.name}
          </Text>
        </Tab>
      </NavLink>
    )
  })

  return (
    <HeaderPortal>
      <Tabs p={2} variant='' colorScheme='' style={{ overflowX: 'scroll' }}>
        <TabList>{headerItemsList}</TabList>
      </Tabs>
    </HeaderPortal>
  )
}

export default CategoryTabBar
