import React from 'react'
import Context from './cart-context'
import { useEffect, useReducer } from 'react'
import { CartProduct } from '../models/CartProduct'
import { ACTION_TYPES, LOCAL_STORAGE } from '../constants/cart'

const defaultCartState = {
  cartProducts: [] as CartProduct[],
  totalAmount: 0,
  totalDeposit: 0,
}

type ActionType =
  | { type: ACTION_TYPES.ADD; product: CartProduct }
  | { type: ACTION_TYPES.REMOVE; id: string }
  | { type: ACTION_TYPES.EMPTY }

const cartReducer = (state: typeof defaultCartState, action: ActionType) => {
  switch (action.type) {
    case ACTION_TYPES.ADD:
      // calculates total amount & total deposit, upto 2 decimal places
      let updatedTotalAmount =
        state.totalAmount + action.product.price * action.product.amount
      updatedTotalAmount = +updatedTotalAmount.toFixed(2)

      let updatedTotalDeposit =
        state.totalDeposit + action.product.deposit * action.product.amount
      updatedTotalDeposit = +updatedTotalDeposit.toFixed(2)

      // checks if the newly added product is already in the cart
      const existingCartProductIndex = state.cartProducts.findIndex(
        (product) => product.id === action.product.id
      )
      const existingCartProduct = state.cartProducts[existingCartProductIndex]

      let updatedCartProducts: CartProduct[]

      if (existingCartProduct) {
        // newly added product is in the cart, so only the amount is updated
        const updatedProduct = {
          ...existingCartProduct,
          amount: existingCartProduct.amount + action.product.amount,
        }

        // cart list is updated with the updated product
        updatedCartProducts = [...state.cartProducts]
        updatedCartProducts[existingCartProductIndex] = updatedProduct
      } else {
        // newly added product is not in the cart, we concat the new product to the existing cart list
        updatedCartProducts = state.cartProducts.concat(action.product)
      }

      return {
        cartProducts: updatedCartProducts,
        totalAmount: updatedTotalAmount,
        totalDeposit: updatedTotalDeposit,
      }

    case ACTION_TYPES.REMOVE: {
      // checks if the newly added product is already in the cart
      const existingCartProductIndex = state.cartProducts.findIndex(
        (product) => product.id === action.id
      )
      const existingCartProduct = state.cartProducts[existingCartProductIndex]

      // calculates total amount & total deposit, upto 2 decimal places
      let updatedTotalAmount = state.totalAmount - existingCartProduct.price
      updatedTotalAmount = +updatedTotalAmount.toFixed(2)

      let updatedTotalDeposit = state.totalDeposit - existingCartProduct.deposit
      updatedTotalDeposit = +updatedTotalDeposit.toFixed(2)

      let updatedCartProducts: CartProduct[]

      if (existingCartProduct.amount === 1) {
        // if there is a product which has only 1 quantity (amount = 1) then we will remove the product from the cart and generate the new cart list
        updatedCartProducts = state.cartProducts.filter(
          (product) => product.id !== action.id
        )
      } else {
        // if a product has more than 1 quantity (amount > 1) then we reduce the amount by 1 and update the cart list
        const updatedProduct = {
          ...existingCartProduct,
          amount: existingCartProduct.amount - 1,
        }
        updatedCartProducts = [...state.cartProducts]
        updatedCartProducts[existingCartProductIndex] = updatedProduct
      }

      return {
        cartProducts: updatedCartProducts,
        totalAmount: updatedTotalAmount,
        totalDeposit: updatedTotalDeposit,
      }
    }

    case ACTION_TYPES.EMPTY: {
      let updatedCartProducts: CartProduct[] = []
      return {
        cartProducts: updatedCartProducts,
        totalAmount: 0,
        totalDeposit: 0,
      }
    }

    default:
      return defaultCartState
  }
}

const CartProvider: React.FC<{}> = (props) => {
  const [cartState, dispatchCartAction] = useReducer(
    cartReducer,
    [],
    // So we only have to pull from localStorage one time
    () =>
      JSON.parse(localStorage.getItem(LOCAL_STORAGE.CART_KEY) as string) ||
      defaultCartState
  )

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE.CART_KEY, JSON.stringify(cartState))
  }, [cartState])

  const addItemToCartHandler = (product: CartProduct) => {
    dispatchCartAction({ type: ACTION_TYPES.ADD, product: product })
  }

  const removeItemFromCartHandler = (id: string) => {
    dispatchCartAction({ type: ACTION_TYPES.REMOVE, id: id })
  }

  const emptyCartHandler = () => {
    dispatchCartAction({ type: ACTION_TYPES.EMPTY })
  }

  const value = {
    cartProducts: cartState.cartProducts,
    totalAmount: cartState.totalAmount,
    totalDeposit: cartState.totalDeposit,
    addProduct: addItemToCartHandler,
    removeProduct: removeItemFromCartHandler,
    emptyCart: emptyCartHandler,
  }

  return <Context.Provider value={value}>{props.children}</Context.Provider>
}

export default CartProvider
