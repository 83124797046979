import { Box } from '@chakra-ui/react'
import CartProductCard from './CartProductCard'
import { VStack } from '@chakra-ui/react'
import { useContext } from 'react'
import context from '../../store/cart-context'

const CartProductsList = () => {
  const cartContext = useContext(context)

  return (
    <Box>
      <VStack align='stretch' spacing={2}>
        {cartContext.cartProducts.map((cartProduct, index) => (
          <CartProductCard key={index} cartProduct={cartProduct} />
        ))}
      </VStack>
    </Box>
  )
}

export default CartProductsList
