import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th
  } from "@chakra-ui/react"
import AdminCategoryListItem from './AdminCategoryListItem'
import useCategories from "../../../hooks/use-categories"

const AdminCategoryList = () => {
    const { loading, error, data } = useCategories()

    if(data === undefined){ return <></> }

    return (
        <>  
            <Box bg="white" border='1px' borderColor='gray.500'>
                <Table variant="striped" colorScheme="gray">
                    <Thead bg="gray.300">
                        <Tr>
                            <Th>Name</Th>
                            <Th>Bearbeiten</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        { data && data.categories?.map(category => {
                            return (
                                <AdminCategoryListItem key={ category.id } categoryID={category.id} categoryName={category.name ?? ''}/>
                            )
                        }) } 
                    </Tbody>
                </Table>
            </Box>
        </>
    )
}

export default AdminCategoryList