import { Box } from '@chakra-ui/react'

import FadeInOut from '../components/Animation/FadeInOut'
import CategoryTabBar from '../components/Layout/CategoryTabBar'
import BackgroundPattern from '../components/Layout/BackgroundPattern'
import Category from './Category'
import Footer from '../components/Layout/Footer'

const ProductsPage = () => (
  <>
    <BackgroundPattern />
    <FadeInOut>
      <CategoryTabBar />
      <Box mt='160px' mb='90px'>
        <Category />
      </Box>
      <Footer />
    </FadeInOut>
  </>
)

export default ProductsPage
