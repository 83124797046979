import { Box, Flex, Text, Spacer, Button, ButtonGroup } from '@chakra-ui/react'
import { CartProduct } from '../../models/CartProduct'
import { convertToDECurrency } from '../../helpers/helpers'
import { useContext } from 'react'
import ContentCard from '../Layout/ContentCard'
import context from '../../store/cart-context'

interface CartProductCardProps {
  cartProduct: CartProduct
  canEditProduct?: boolean
}

const CartProductCard: React.FC<CartProductCardProps> = ({
  cartProduct,
  canEditProduct,
}) => {
  const cartContext = useContext(context)

  const { id, name, amount, packingUnitAmount } = cartProduct
  const price = convertToDECurrency(cartProduct.price)
  const deposit = convertToDECurrency(cartProduct.deposit)

  // increases the amount of the product by 1
  const addProductHandler = () => {
    cartContext.addProduct({ ...cartProduct, amount: 1 })
  }

  const removeProductHandler = () => {
    cartContext.removeProduct(id)
  }

  return (
    <ContentCard>
      <Box p='6'>
        <Box
          mb={1}
          fontWeight='bold'
          as='h4'
          lineHeight='tight'
          isTruncated
          fontSize='lg'
        >
          {name}
        </Box>

        <Flex>
          <Text fontSize='sm'>{packingUnitAmount}</Text>
          <Spacer />
          <Text fontSize='sm' fontWeight='bold'>
            {price}
          </Text>
        </Flex>

        <Text fontSize='xs' color='gray.600'>
          zzgl. {deposit} Pfand
        </Text>

        <Flex direction='row-reverse'>
          <ButtonGroup size='xs' variant='outline' spacing='1'>
            <Button
              fontSize='sm'
              color='primary'
              disabled={canEditProduct}
              _hover={{
                boxShadow: 'outline',
                p: '2',
                rounded: 'md',
              }}
              onClick={removeProductHandler}
            >
              {' '}
              -{' '}
            </Button>

            <Text
              fontSize='sm'
              fontWeight='bold'
              textColor='white'
              bg='primary'
              pl={2}
              pr={2}
              borderRadius={15}
            >
              {amount}
            </Text>

            <Button
              fontSize='sm'
              color='primary'
              disabled={canEditProduct}
              _hover={{
                boxShadow: 'outline',
                p: '2',
                rounded: 'md',
              }}
              onClick={addProductHandler}
            >
              {' '}
              +{' '}
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </ContentCard>
  )
}

export default CartProductCard
