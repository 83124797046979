import {
    Tr,
    Td,
    HStack
  } from "@chakra-ui/react"
import UpdateCategory from './UpdateCategory'
import DeleteCategory from './DeleteCategory'

interface AdminCategoryListItemProps {
    categoryID: string,
    categoryName: string
}
const AdminCategoryListItem = ({categoryID, categoryName} : AdminCategoryListItemProps) => {
    return (
        <>
            <Tr>
                <Td textTransform="capitalize">{categoryName}</Td>
                <Td>
                    <HStack>
                        <UpdateCategory id={ categoryID } name={ categoryName }/> 
                        <DeleteCategory categoryID={ categoryID }/>
                    </HStack>
                </Td>
            </Tr>
        </>
    )
}

export default AdminCategoryListItem