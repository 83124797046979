import { Box } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'

import { Switch, Route, Redirect } from 'react-router-dom'

import PageHeader from './components/Layout/PageHeader'

import ProductsPage from './pages/ProductsPage'
import CartPage from './pages/CartPage'
import CheckoutPage from './pages/CheckoutPage'
import SummaryPage from './pages/SummaryPage'
import SuccessPage from './pages/SuccessPage'
import AdminPage from './pages/AdminPage'
import AdminCategoryPage from './pages/AdminCategoryPage'
import AdminProductPage from './pages/AdminProductPage'

import Provider from './store/CartProvider'

function App() {
  return (
    <Box minH='full'>
      <PageHeader />

      <Provider>
        <AnimatePresence exitBeforeEnter>
          <Switch>
            <Route path='/' exact>
              <Redirect to='/category/1' />
            </Route>

            <Route path='/category/:categoryId'>
              <ProductsPage />
            </Route>

            <Route path='/warenkorb' exact>
              <CartPage />
            </Route>

            <Route path='/warenkorb/checkout'>
              <CheckoutPage />
            </Route>

            <Route path='/warenkorb/summary'>
              <SummaryPage />
            </Route>

            <Route path='/warenkorb/danke'>
              <SuccessPage />
            </Route>

            <Route path='/admin' exact>
              <AdminPage />
            </Route>

            <Route path='/admin/kategorien'>
              <AdminCategoryPage />
            </Route>

            <Route path='/admin/produkte'>
              <AdminProductPage />
            </Route>

            {/* TODO:: 404 page */}
          </Switch>
        </AnimatePresence>
      </Provider>
    </Box>
  )
}

export default App
