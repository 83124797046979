import PackagingUnitsListItem from './PackagingUnitsListItem'
import { Products_products_packagingUnits } from '../../schema/Products'

interface ProductCardPackagingUnitsProps {
  productCardName: string
  packagingUnit: Products_products_packagingUnits[]
}

const ProductCardPackagingUnits = ({productCardName, packagingUnit} : ProductCardPackagingUnitsProps) => (
  <>
    {packagingUnit.map((product, index) => (
      <PackagingUnitsListItem
        key={index}
        productCardName={productCardName}
        packagingUnitItem={product}
      />
    ))}
  </>
)

export default ProductCardPackagingUnits
