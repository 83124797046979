import React from 'react'
import { CartProduct } from '../models/CartProduct'

const Context = React.createContext({
  cartProducts: [] as CartProduct[],
  totalAmount: 0,
  totalDeposit: 0,
  addProduct: (product: CartProduct) => {},
  removeProduct: (id: string) => {},
  emptyCart: () => {},
})

export default Context
