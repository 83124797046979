import { Box } from '@chakra-ui/react'
import Summary from './Summary'
import CartFooter from '../components/Cart/CartFooter'
import FadeInOut from '../components/Animation/FadeInOut'
import BackgroundPattern from '../components/Layout/BackgroundPattern'

const SummaryPage = () => {
    return (
        <> 
            <BackgroundPattern/>
            <FadeInOut>
                <Box mt="160px" mb="170px">
                    <Summary/>
                </Box>
                <CartFooter forwardRouteLabel='Bestellen' />
            </FadeInOut>
        </>
    )
}

export default SummaryPage