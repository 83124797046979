import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { DeepMap, FieldError, get } from 'react-hook-form'

interface BaseFormFieldProps<T> {
  errors: DeepMap<T, FieldError>
  name: string
  title?: string
}

const BaseFormField: <T>(
  p: PropsWithChildren<BaseFormFieldProps<T>>
) => React.ReactElement = (props) => {
  const error = props.errors && props.name && get(props.errors, props.name)

  return (
    <FormControl id={props.name} isInvalid={error}>
      {props.title && <FormLabel>{props.title}</FormLabel>}
      {props.children}
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  )
}

export default BaseFormField
