import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Center
  } from "@chakra-ui/react"
import AdminProductTableRow from './AdminProductTableRow'
import { gql, useQuery } from '@apollo/client'
import { BackofficeProducts } from "../../../schema/BackofficeProducts"

const BACK_OFFICE_PRODUCTS = gql`
    query BackofficeProducts {
        backofficeProducts {
            id
            categoryId
            name
            description
            image
        }
    }
`

const AdminProductTable = () => {
    const { loading, error, data } = useQuery<BackofficeProducts>(BACK_OFFICE_PRODUCTS)

    if(data === undefined){ return <></> }

    return (
        <>
            <Table colorScheme="gray" bg="white">
                <Thead>
                    <Tr>
                        <Th><Center>Bild</Center></Th>
                        <Th p={0}>Name</Th>
                        <Th p={0} pl={3}>Beschreibung</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    { data && data.backofficeProducts?.map(product => {
                        return <AdminProductTableRow key={product.id} product={product}/>
                    }) }
                </Tbody>
            </Table>
        </>
    )
}

export default AdminProductTable 