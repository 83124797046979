import { Flex, Spacer, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import context from '../../store/cart-context'
import { convertToDECurrency } from '../../helpers/helpers'

const CartFooter = () => {
  const cartContext = useContext(context)

  let totalAmount = cartContext.totalAmount
  let totalDeposit = cartContext.totalDeposit
  let totalAmountFormatted = convertToDECurrency(totalAmount)
  let totalDepositFormatted = convertToDECurrency(totalDeposit)

  // final price after adding total amount and Pfands
  const finalPrice = totalAmount + totalDeposit
  let finalPriceFormatted = convertToDECurrency(finalPrice)

  return (
    <>
      <Flex>
        <Text fontSize='sm' fontWeight='bold'>
          Warenwert
        </Text>
        <Spacer />
        <Text fontSize='sm' fontWeight='bold'>
          {totalAmountFormatted}
        </Text>
      </Flex>

      <Flex>
        <Text fontSize='sm'>zzgl. Pfand</Text>
        <Spacer />
        <Text fontSize='sm'>{totalDepositFormatted}</Text>
      </Flex>

      <Flex>
        <Text fontSize='sm'>Gesamt</Text>
        <Spacer />
        <Text fontSize='sm'>{finalPriceFormatted}</Text>
      </Flex>
    </>
  )
}

export default CartFooter
