import CheckoutForm from '../components/Checkout/CheckoutForm'
import HeaderPortal from '../components/Layout/HeaderPortal'
import HeaderTitle from '../components/Layout/HeaderTitle'

const Checkout = () => {
  return (
    <>
      <HeaderPortal>
        <HeaderTitle title='Lieferadresse eingeben' />
      </HeaderPortal>

      <CheckoutForm />
    </>
  )
}

export default Checkout
