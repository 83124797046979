import { Center } from "@chakra-ui/react"
import BackgroundPattern from '../components/Layout/BackgroundPattern'
import Success from './Success'

const SuccessPage = () => (
    <>
        <BackgroundPattern/>
        <Center width="100%" height="100vh">
            <Success/>
        </Center>
    </>
)

export default SuccessPage