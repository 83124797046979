export enum ACTION_TYPES {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  EMPTY = 'EMPTY',
}

export enum LOCAL_STORAGE {
  CART_KEY = 'cart-items',
  USER = 'user',
}
