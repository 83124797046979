import { gql, useQuery } from '@apollo/client'
import { Categories } from '../schema/Categories'

const GET_CATEGORIES =  gql`
    query Categories {
        categories {
            id
            name
        }
    }
`

const useCategories = () => (
    useQuery<Categories>(GET_CATEGORIES)
)

export default useCategories