import { Box } from '@chakra-ui/react'
import AdminCategory from '../components/Admin/AdminCategory/AdminCategory'

const AdminCategoryPage = () => {
    return (
        <>
            <Box mt='160px'>
                <AdminCategory/>
            </Box>
        </>
    )
}

export default AdminCategoryPage