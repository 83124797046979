import ProductCard from './ProductCard'
import { VStack } from '@chakra-ui/react'
import useProducts from '../../hooks/use-products'

interface CategoryListProps {
  categoryId: string
}

const CategoryList = ({ categoryId } : CategoryListProps) => {
  const { loading, error, data } = useProducts(categoryId)

  if(data === undefined){ return <></> }
  
  return (
    <VStack align='stretch' spacing={3}>
      { data && data.products?.map((product: any) => (
        <ProductCard key={ product.name } product={product} />
      ))}
    </VStack>
  )
}

export default CategoryList
