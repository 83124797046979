import { Box, Container } from '@chakra-ui/layout'
import Logo from './Logo'

const PageHeader = () => (
  <Box position='fixed' w='full' bg='white' zIndex='10' top='0'>
    <Box p='3'>
      <Container maxW='container.lg' bg='white'>
        <Logo />
      </Container>
    </Box>
    <Box id='header' bg='secondary' py='1' h='16' />
  </Box>
)

export default PageHeader
