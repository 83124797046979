import { Box, VStack } from '@chakra-ui/react'
import { useContext } from 'react'
import context from '../../store/cart-context'
import CartProductCard from '../Cart/CartProductCard'

const SummaryProductsList = () => {
  const cartContext = useContext(context)

  let cartProductsList = cartContext.cartProducts.map((cartProduct, index) => {
    return (
      <CartProductCard
        key={index}
        cartProduct={cartProduct}
        canEditProduct={true}
      />
    )
  })

  return (
    <>
      <Box>
        <VStack align='stretch' spacing={2}>
          {cartProductsList}
        </VStack>
      </Box>
    </>
  )
}

export default SummaryProductsList
