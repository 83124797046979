import { Button } from '@chakra-ui/react'
import { gql, useMutation } from '@apollo/client'
import { DeleteCategory as DeleteCategoryType } from '../../../schema/DeleteCategory'

interface DeleteCategoryProps {
    categoryID: string
}

const DELETE_CATEGORY = gql`
  mutation DeleteCategory($categoryId: ID!) {
    deleteCategory(categoryId: $categoryId)
  }
`

const DeleteCategory = ({ categoryID }: DeleteCategoryProps) => {
    const [deleteCategoryMutation, { loading, error, data }] =
    useMutation<DeleteCategoryType>(DELETE_CATEGORY, {
      update(cache, { data }) {
        cache.modify({
          fields: {
            categories(existingCategories = []) {
              if (data?.deleteCategory) {
                existingCategories = existingCategories.filter((category: string) => {
                    return category !== data?.deleteCategory
                })
              } else {
                return existingCategories
              }
            },
          },
        })
      },
    })

    const deleteBtnHandler = async () => {
        try {
            await deleteCategoryMutation({
              variables: { categoryId: categoryID },
            })
          } catch (error) {
            alert(error)
        }
    }

    return (
        <>
            <Button size="sm" onClick={deleteBtnHandler}>löschen</Button>
        </>
    )
}

export default DeleteCategory