import { Box, Center, VStack, Button, Text } from '@chakra-ui/react'
import { MdShoppingCart } from 'react-icons/md'
import { useState, useEffect, useContext, useRef } from 'react'
import context from '../../store/cart-context'
import { CartProduct } from '../../models/CartProduct'
import { Link } from 'react-router-dom'
import { convertToDECurrency } from '../../helpers/helpers'
import classes from './ShoppingCartGlowEffect.module.css'

const Footer = () => {
  const cartContext = useContext(context)
  const cartProducts = cartContext.cartProducts

  const [cartBtnGlowEffect, setCartBtnGlowEffect] = useState(false)
  const isUseEffectFirstRun = useRef(true)

  useEffect(() => {
    // check isUseEffectFirstRun to not show the cart btn glow effect for the initial load
    if (isUseEffectFirstRun.current) {
      isUseEffectFirstRun.current = false
      return
    }

    setCartBtnGlowEffect(true)
    const timer = setTimeout(() => {
      setCartBtnGlowEffect(false)
    }, 700)

    return () => {
      clearTimeout(timer)
    }
  }, [cartProducts])

  let btnGlowEffect = `${cartBtnGlowEffect ? classes.glow : ''}`

  const totalProducts = cartProducts.reduce(
    (currentValue, item: CartProduct) => {
      return currentValue + item.amount
    },
    0
  )

  let totalAmount = cartContext.totalAmount
  let totalAmountFormatted = convertToDECurrency(totalAmount)

  return (
    <>
      <Box
        p={3}
        w='100%'
        borderTopWidth='1px'
        borderColor='gray.200'
        bg='white'
        position='fixed'
        bottom='0'
      >
        <Center>
          <Link className={btnGlowEffect} to={'/warenkorb'}>
            <Button
              leftIcon={<MdShoppingCart size={40} />}
              color='primary'
              variant='ghost'
              p={7}
            >
              <VStack
                direction='column'
                spacing={1}
                p='1'
                alignItems='right'
              >
                <Text fontSize='md' color='black' align='right'>
                  {totalAmountFormatted}
                </Text>
                <Text fontSize='sm' color='gray.500' align='right'>
                  {totalProducts} Artikel
                </Text>
              </VStack>
            </Button>
          </Link>
        </Center>
      </Box>
    </>
  )
}

export default Footer
