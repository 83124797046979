import FadeInOut from '../components/Animation/FadeInOut'
import BackgroundPattern from '../components/Layout/BackgroundPattern'
import Cart from './Cart'
import { Box } from '@chakra-ui/react'

const CartPage = () => (
  <>
    <BackgroundPattern />
    <FadeInOut>
      <Box mt='160px' mb='220px'>
        <Cart />
      </Box>
    </FadeInOut>
  </>
)

export default CartPage
