import { Box, Heading, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { getAddress } from '../../services/AddressService'

const AddressCard = () => {
  const address = getAddress()

  if (!address) {
    return <></>
  }

  const { addressLine1, addressLine2, name, postcode, city } = address

  return (
    <Box>
      <Heading size='md' fontWeight='semibold'>
        Lieferadresse
      </Heading>

      <Link to='/warenkorb/checkout'>
        <Text fontSize='sm' color='black' _hover={{ color: 'gray.500' }} mb={3}>
          ändern
        </Text>
      </Link>

      <Text fontSize='md' fontWeight='normal'>
        {name}
      </Text>

      <Text fontSize='md' fontWeight='normal'>
        {addressLine1}
      </Text>

      {addressLine2 && (
        <Text fontSize='md' fontWeight='normal'>
          {addressLine2}
        </Text>
      )}

      <Text fontSize='md' fontWeight='normal'>
        {postcode} {city}
      </Text>
    </Box>
  )
}

export default AddressCard
