import CartProductsList from '../components/Cart/CartProductsList'
import CartFooter from '../components/Cart/CartFooter'
import HeaderPortal from '../components/Layout/HeaderPortal'
import HeaderTitle from '../components/Layout/HeaderTitle'
import ContentContainer from '../components/Layout/ContentContainer'
import context from '../store/cart-context'
import { useState, useContext, useEffect } from 'react'
import EmptyCartView from '../components/Cart/EmptyCartView'

const Cart = () => {
  const cartCtx = useContext(context)
  const [isCartEmpty, setIsCartEmpty] = useState(true)

  useEffect(() => {
    if (cartCtx.cartProducts.length === 0) setIsCartEmpty(true)
    else setIsCartEmpty(false)
  }, [cartCtx.cartProducts])

  return (
    <>
      <HeaderPortal>
        <HeaderTitle title='Warenkorb' />
      </HeaderPortal>
      <ContentContainer>
        {isCartEmpty ? <EmptyCartView /> : <CartProductsList />}
      </ContentContainer>
      <CartFooter
        forwardLinkRoute='/warenkorb/checkout'
        forwardRouteLabel='Adresse eingeben'
        isBackBtnShown={true}
      />
    </>
  )
}

export default Cart
