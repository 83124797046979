import { Box } from '@chakra-ui/react'
import Admin from '../components/Admin/Admin'

const AdminPage = () => {
    return (
        <>
            <Box mt='160px'>
                <Admin/>
            </Box>
        </>
    )
}

export default AdminPage 