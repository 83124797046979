import { LOCAL_STORAGE } from '../constants/cart'
import { AddressInput } from '../schema/globalTypes'

export const setAddress = (address: AddressInput) => {
  localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(address))
}

export const getAddress = () => {
  const json = localStorage.getItem(LOCAL_STORAGE.USER)
  const address: AddressInput | undefined = json ? JSON.parse(json) : null
  return address
}
