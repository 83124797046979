import { VStack, Box } from "@chakra-ui/layout"
import { NavLink } from "react-router-dom"

const AdminSideMenu = () => {
    return (
        <>  
            <VStack spacing={8} m={20}>
                <Box _hover={{ fontWeight: 'bold' }}>
                    <NavLink to='/admin/kategorien'
                        style={{
                            color: 'black',
                        }}
                        activeStyle={{
                            color: 'white',
                            fontWeight: 'bold',
                            backgroundColor: 'gray',
                            borderRadius: 3,
                            padding: 10
                        }}
                    >Kategorien</NavLink>
                </Box>
                
                <Box _hover={{ fontWeight: 'bold' }}>
                    <NavLink to='/admin/produkte'
                        style={{
                            color: 'black',
                        }}
                        activeStyle={{
                            color: 'white',
                            fontWeight: 'bold',
                            backgroundColor: 'gray',
                            borderRadius: 3,
                            padding: 10
                        }}
                    >Produkte</NavLink>
                </Box>
            </VStack>
        </>
    )
}

export default AdminSideMenu