import { useState } from 'react'
import { Stack, Img, Flex, Spacer, Button, Collapse, Box } from '@chakra-ui/react'
import ProductCardName from './ProductCardName'
import ContentCard from '../Layout/ContentCard'
import ProductCardPackagingUnits from './ProductCardPackagingUnits'
import ProductDetailsView from './ProductDetailsView'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import { Products_products } from '../../schema/Products'

interface ProductCardProps {
  product: Products_products
}

const ProductCard = ({ product } : ProductCardProps) => {
  const [productDetailsIsShown, setProductDetailsIsShown] = useState(false)

  return (
    <ContentCard>
      <Flex>
        <Img
          src={product.image ?? ''}
          // boxSize={"120px"}
          width='120px'
          minW='120px'
          maxHeight='200px'
          objectFit='contain'
          mt={2}
          ml='-8'
          alignSelf={'center'}
        />
        <Stack direction={'column'} flex={1} minW={0}>
          <ProductCardName name={product.name} />
          <ProductCardPackagingUnits
            productCardName={product.name}
            packagingUnit={product.packagingUnits ?? []}
          />
        </Stack>
      </Flex>

      { !productDetailsIsShown && 
        <Flex mr="-10px">
          <Spacer />
          <Button size="xs" textColor="gray.500" bg="white" _hover={{textColor: "white", bg: "gray.500"}} onClick={() => setProductDetailsIsShown(!productDetailsIsShown)}>
            <FaArrowDown/> <Box ml="2px">Details anzeigen</Box> 
          </Button>
        </Flex>
      }
      
      <Collapse in={productDetailsIsShown} animateOpacity>
        { product.description && <ProductDetailsView description={product.description}/> }
      </Collapse>

      { productDetailsIsShown && 
        <Flex mr="-10px">
          <Spacer />
          <Button size="xs" textColor="gray.500" bg="white" _hover={{textColor: "white", bg: "gray.500"}} onClick={() => setProductDetailsIsShown(!productDetailsIsShown)}>
            <FaArrowUp/> <Box ml="2px">ausblenden</Box> 
          </Button>
        </Flex>
      }
    </ContentCard>
  )
}

export default ProductCard
