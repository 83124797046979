import { Flex, Text } from '@chakra-ui/react'

interface HeaderTitleProps {
  title: string
}

const HeaderTitle = ({ title }: HeaderTitleProps) => (
  <Flex justifyContent='center' alignItems='center' flex='1' height='full'>
    <Text color='white' fontWeight='semibold' fontSize='xl'>
      {title}
    </Text>
  </Flex>
)

export default HeaderTitle
