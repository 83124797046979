import { Box } from '@chakra-ui/react'

const BackgroundPattern = () => (
  <Box
    height='100vh'
    width='full'
    zIndex='-10'
    position='fixed'
    top='0'
    backgroundImage="url('/pattern.svg')"
    backgroundColor='gray.100'
    backgroundAttachment='fixed'
    // display={{ base: "none", lg: "block" }}
  ></Box>
)

export default BackgroundPattern
