import { Input, Button, VStack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client'
import { UpdateCategory as UpdateCategoryType } from '../../../schema/UpdateCategory'
import BaseFormField from '../../Checkout/BaseFormField'

interface UpdateCategoryFormProps {
    id: string,
    currentCategoryName: string,
    onClose: () => void
}

interface UpdateCategory {
    name: string 
}

const UPDATE_CATEGORY_QUERY = gql`
  mutation UpdateCategory($updateCategory: CategoryUpdate!) {
    updateCategory(updateCategory: $updateCategory) {
      id
      name
    }
  }
`

const UpdateCategoryForm = ({ id, currentCategoryName, onClose }: UpdateCategoryFormProps) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm<UpdateCategory>()

    const [UpdateCategoryMutation, { loading, error, data }] =
    useMutation<UpdateCategoryType>(UPDATE_CATEGORY_QUERY)

    const onSubmit = async (updatedCategory: UpdateCategory) => {
        try {
            await UpdateCategoryMutation({
              variables: { updateCategory: { id: id, name: updatedCategory.name } },
            })

            reset()
            onClose()
          } catch (error) {
            alert(error)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <VStack>
                    <BaseFormField name='name' errors={errors}>
                        <Input
                            defaultValue={currentCategoryName}
                            placeholder='Kategorien'
                            {...register('name', { required: 'Gib deinen Kategorien ein.' })}
                        />
                    </BaseFormField>

                    <Button size="sm" variant="outline" colorScheme="green" w="full" type='submit'>Update</Button>
                </VStack>
            </form>
        </>
    )
}

export default UpdateCategoryForm 