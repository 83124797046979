import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Container, Box } from '@chakra-ui/react'

const HeaderPortal: React.FC = ({ children }) => {
  const ref = useRef<HTMLElement | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.getElementById('header')
    setMounted(true)
  }, [])

  return mounted && ref.current
    ? createPortal(
        <Box bg='secondary' py='1' h='16'>
          <Container maxW='container.lg' height='full'>
            {children}
          </Container>
        </Box>,
        ref.current
      )
    : null
}

export default HeaderPortal
