import { gql, useQuery } from '@apollo/client'
import { Products } from '../schema/Products'

const GET_PRODUCTS = gql`
    query Products($categoryId: ID) {
        products(categoryId: $categoryId){
            id
            categoryId
            name
            description
            packagingUnits {
                id
                packingUnitAmount
                unit
                deposit
                pricePerLitre
                price
            }
            image
        }
    }
`

const useProducts = (categoryId: string) => (
    useQuery<Products>(GET_PRODUCTS, {
        variables: {
            categoryId: categoryId
        }
    })
)

export default useProducts 