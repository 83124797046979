import { Stack, Button } from '@chakra-ui/react'
import { useHistory, NavLink } from 'react-router-dom'
import context from '../../store/cart-context'
import { useContext } from 'react'
import { gql, useMutation } from '@apollo/client'
import { placeOrder, placeOrderVariables } from '../../schema/placeOrder'
import { useToast } from '@chakra-ui/react'
import { getAddress } from '../../services/AddressService'

const CartFooterNav: React.FC<{
  forwardLinkRoute?: string
  forwardRouteLabel?: string
  isBackBtnShown?: boolean
}> = (props) => {
  const cartContext = useContext(context)
  let history = useHistory()
  const toast = useToast()

  let products = cartContext.cartProducts.map((cartProduct) => {
    return {
      name: cartProduct.name,
      packingUnit: cartProduct.packingUnitAmount,
      amount: cartProduct.amount,
    }
  })

  const address = getAddress()

  const PLACE_ORDER = gql`
    mutation placeOrder($input: OrderInput!) {
      placeOrder(input: $input) {
        message
      }
    }
  `

  const [placeOrderMutation, { loading, error, data }] = useMutation<
    placeOrder,
    placeOrderVariables
  >(PLACE_ORDER)

  const orderBtnHandler = async () => {
    try {
      if (address && products) {
        await placeOrderMutation({
          variables: { input: { address, products } },
        })
        cartContext.emptyCart()
        history.push('/warenkorb/danke')
      }
    } catch (error) {
      toast({
        title: 'Fehler',
        description:
          'Ups, etwas ist schiefgegangen. Bitte probieren Sie es später erneut.',
        status: 'error',
        duration: 1000,
        isClosable: true,
      })
    }
  }

  return (
    <>
      <Stack mt={6} alignItems='center' direction='column'>
        {/* Adresse eingeben Btn */}
        {props.forwardLinkRoute && (
          <NavLink to={props.forwardLinkRoute} style={{ width: '100%' }}>
            <Button
              variant=''
              size='md'
              color='white'
              bg='primary'
              _hover={{color: 'primary', bg: 'gray.100'}}
              fontWeight='bold'
              w='full'
              rounded='sm'
              disabled={cartContext.cartProducts.length === 0}
            >
              {props.forwardRouteLabel}
            </Button>
          </NavLink>
        )}

        {/* Bestellen Btn */}
        {!props.forwardLinkRoute && (
          <Button
            variant=''
            size='md'
            color='white'
            bg='primary'
            _hover={{color: 'primary', bg: 'gray.100'}}
            fontWeight='bold'
            w='full'
            isLoading={loading}
            onClick={orderBtnHandler}
          >
            {props.forwardRouteLabel}
          </Button>
        )}

        {/* Back Btn */}
        {props.isBackBtnShown && (
          <Button
            size='md'
            variant=''
            _hover={{color: 'primary', bg: 'gray.100'}}
            w='full'
            onClick={() => history.push('/')}
            disabled={loading}
          >
            weiter einkaufen
          </Button>
        )}
      </Stack>
    </>
  )
}

export default CartFooterNav
