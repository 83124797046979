import { Box, Text } from '@chakra-ui/react'

const ProductCardName: React.FC<{ name: String }> = ({ name }) => {
  return (
    <Box mt='1' fontWeight='bold' as='h4' lineHeight='tight' fontSize='lg'>
      <Text>{name}</Text>
    </Box>
  )
}

export default ProductCardName
