import { Box, Container } from '@chakra-ui/react'
import CartFooterDetails from './CartFooterDetails'
import CartFooterNav from './CartFooterNav'

const CartFooter: React.FC<{
  forwardLinkRoute?: string
  forwardRouteLabel?: string
  isBackBtnShown?: boolean
}> = (props) => {
  return (
    <>
      <Box
        position='fixed'
        bottom={0}
        p={4}
        w='100%'
        borderTop='1px'
        borderColor='gray.200'
        bg='white'
      >
        <Container maxW='container.lg'>
          <CartFooterDetails />
          <CartFooterNav
            forwardLinkRoute={props.forwardLinkRoute}
            forwardRouteLabel={props.forwardRouteLabel}
            isBackBtnShown={props.isBackBtnShown}
          />
        </Container>
      </Box>
    </>
  )
}

export default CartFooter
