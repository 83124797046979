import { Center } from '@chakra-ui/react'
import AdminSideMenu from './AdminSideMenu'
import ContentContainer from '../Layout/ContentContainer'

const Admin = () => {
    return (
        <>
            <ContentContainer>
                <Center>
                    <AdminSideMenu/>
                </Center>
            </ContentContainer>
        </>
    )
}

export default Admin 