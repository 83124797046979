import { Grid, GridItem, Center } from '@chakra-ui/react'
import ContentContainer from '../../Layout/ContentContainer'
import AdminSideMenu from '../AdminSideMenu'
import HeaderPortal from '../../Layout/HeaderPortal'
import HeaderTtile from '../../Layout/HeaderTitle'
import AdminProductTable from './AdminProductTable'

const AdminCategory = () => {
    return (
        <>
            <HeaderPortal>
                <HeaderTtile title="Produkte"/>
            </HeaderPortal>
            <ContentContainer>
                <Grid templateColumns="repeat(8, 1fr)" gap={4}>
                    <GridItem colSpan={{ base: 8, md: 2 }}>
                        <Center>
                            <AdminSideMenu/>
                        </Center>
                    </GridItem>
                    <GridItem colSpan={{ base: 8, md: 6 }} mt={{ base: 0, md: 10 }} mb={{ base: 20, md: 20 }}>
                        <AdminProductTable/>
                    </GridItem>
                </Grid>
            </ContentContainer>
        </>
    )
}

export default AdminCategory