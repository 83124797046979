import { NavLink } from 'react-router-dom'
import { Box, Center, Image } from '@chakra-ui/react'

const Logo = () => {
  return (
    <Center>
      <NavLink to='/'>
        <Box h='60px'>
          <Image
            height='100%'
            objectFit='contain'
            src='/logo.png'
            alt='Onlineshop'
          />
        </Box>
      </NavLink>
    </Center>
  )
}

export default Logo
