import { MdShoppingCart } from 'react-icons/md'
import { Button, VStack, Heading } from '@chakra-ui/react'
import { useHistory } from 'react-router'

const EmptyCartView = () => {
  const history = useHistory()
  return (
    <>
      <VStack>
        <MdShoppingCart size={100} />
        <Heading as='h4' size='md'>
          Ihr Warenkorb ist leer
        </Heading>
        <Button
          variant=''
          size='md'
          color='white'
          bg='primary'
          _hover={{color: 'primary', bg: 'gray.200'}}
          fontWeight='bold'
          onClick={() => history.push('/')}
        >
          Shop durchstöbern
        </Button>
      </VStack>
    </>
  )
}

export default EmptyCartView
