import {
  Grid,
  GridItem,
  Center,
  Flex,
  Spacer,
  Button,
  VStack,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react'
import AdminSideMenu from '../AdminSideMenu'
import ContentContainer from '../../Layout/ContentContainer'
import HeaderPortal from '../../Layout/HeaderPortal'
import HeaderTtile from '../../Layout/HeaderTitle'
import AdminCategoryList from './AdminCategoryList'
import CreateCategory from './CreateCategoryForm'

const AdminCategory = () => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <>
      <HeaderPortal>
        <HeaderTtile title='Kategorien' />
      </HeaderPortal>
      <ContentContainer>
        <Grid templateColumns='repeat(8, 1fr)' gap={4}>
          <GridItem colSpan={{ base: 8, md: 2 }}>
            <Center>
              <AdminSideMenu />
            </Center>
          </GridItem>
          <GridItem colSpan={{ base: 8, md: 6 }} m={{ base: 0, md: 10 }}>
            <VStack align='left' spacing={5}>
              <Flex>
                <Spacer />
                <Button
                  size='sm'
                  variant='outline'
                  colorScheme='green'
                  onClick={onToggle}
                >
                  Neu
                </Button>
              </Flex>

              <Collapse in={isOpen} animateOpacity>
                <CreateCategory onToggle={() => onToggle()} />
              </Collapse>
              <AdminCategoryList />
            </VStack>
          </GridItem>
        </Grid>
      </ContentContainer>
    </>
  )
}

export default AdminCategory
