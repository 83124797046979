import Lottie from 'react-lottie'
import animationData from './check-mark-success.json'

const SuccessAnimation = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <>
      <Lottie options={defaultOptions} height={100} width={100} />
    </>
  )
}

export default SuccessAnimation
