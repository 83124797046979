import { Tr, Td, Img } from '@chakra-ui/react'
import { BackofficeProducts_backofficeProducts } from '../../../schema/BackofficeProducts'

interface AdminProductTableRowProps {
    product: BackofficeProducts_backofficeProducts
}

const AdminProductTableRow = ({ product }: AdminProductTableRowProps) => {
    return (
        <>
            <Tr>
                <Td px={{ base: 0, md: 6 }} py={{ base: 4, md: 4 }}>
                    <Img 
                        src={product.image ?? ''}
                        width='120px'
                        minW='120px'
                        maxHeight='200px'
                        objectFit='contain'
                        alignSelf={'center'}
                    />
                </Td>
                <Td p={0} fontSize="sm">{ product.name }</Td>
                <Td p={4} pr={{ base: 0, md: 4 }} fontSize="sm">{ product.description }</Td>
            </Tr>
        </>
    )
}

export default AdminProductTableRow