import { Box } from '@chakra-ui/react'

const ContentCard: React.FC = ({ children }) => (
  <Box
    borderWidth='1px'
    borderRadius='sm'
    overflow='hidden'
    py='5'
    px='8'
    bg='white'
    shadow='sm'
  >
    {children}
  </Box>
)

export default ContentCard
