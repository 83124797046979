import {
  Flex,
  Box,
  Spacer,
  Text,
  Button,
  Divider,
  ButtonGroup,
} from '@chakra-ui/react'

import { AddIcon, MinusIcon } from '@chakra-ui/icons'

import { useContext } from 'react'
import context from '../../store/cart-context'
import { convertToDECurrency } from '../../helpers/helpers'
import { Products_products_packagingUnits } from '../../schema/Products'

interface PackagingUnitsListItemProps {
  productCardName: string,
  packagingUnitItem: Products_products_packagingUnits
}

const PackagingUnitsListItem = ({ productCardName, packagingUnitItem } : PackagingUnitsListItemProps ) => {
  const cartCtx = useContext(context)

  let id = packagingUnitItem.id ?? ''
  let name = productCardName
  let packingUnitAmount = packagingUnitItem.packingUnitAmount ?? ''
  let price = packagingUnitItem.price ?? 0
  let deposit = packagingUnitItem.deposit ?? 0
  let pricePerLitre = packagingUnitItem.pricePerLitre ?? 0

  let priceFormatted = convertToDECurrency(price)
  let depositFormatted = convertToDECurrency(deposit)

  // checks if the product is already in the cart
  const existingCartProductIndex = cartCtx.cartProducts.findIndex(
    (item) => item.id === id
  )
  const existingCartProduct = cartCtx.cartProducts[existingCartProductIndex]

  let amount = 0
  if (existingCartProduct) {
    amount = existingCartProduct.amount
  }
  let isAmountMoreThanZero = amount > 0

  const addProductHandler = () => {
    cartCtx.addProduct({
      id: id,
      name: name,
      packingUnitAmount: packingUnitAmount,
      amount: 1,
      price: price,
      deposit: deposit,
    })
  }

  const removeProductHandler = () => {
    cartCtx.removeProduct(id)
  }

  return (
    <>
      <Divider mt={2} mb={2} />
      <Flex direction={'column'}>
        <Flex>
          <Text fontSize='sm'>{packingUnitAmount}</Text>
          <Spacer />
          <Text fontSize='sm' fontWeight='bold'>
            {priceFormatted}
          </Text>
        </Flex>
        <Flex mt={2}>
          <Flex direction='column'>
            <Text fontSize='xs' color='gray.400'>
              zzgl. {depositFormatted} Pfand
            </Text>
            <Text fontSize='xs' color='gray.400'>
              {pricePerLitre}
            </Text>
          </Flex>
          <Spacer />
          <Box mt={2}>
            <ButtonGroup size='xs' variant='outline' spacing='1'>
              {isAmountMoreThanZero && (
                <Button
                  color='primary'
                  variant='ghost'
                  onClick={removeProductHandler}
                >
                  <MinusIcon w='5' h='5' />
                </Button>
              )}

              {isAmountMoreThanZero && (
                <Text
                  fontSize='md'
                  fontWeight='bold'
                  textColor='white'
                  bg='primary'
                  pl={2}
                  pr={2}
                  borderRadius={18}
                >
                  {amount}
                </Text>
              )}

              <Button
                color='primary'
                variant='ghost'
                onClick={addProductHandler}
              >
                <AddIcon w='5' h='5' />
              </Button>
            </ButtonGroup>
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

export default PackagingUnitsListItem
