import { extendTheme } from '@chakra-ui/react'

const colors = {
  primary: '#ed1c23',
  secondary: '#006992',
}

const styles = {
  global: {
    // styles for the `body`
    body: {
      // backgroundImage: "url('/pattern.svg')",
      // backgroundColor: "gray.100",
      // backgroundAttachment: "fixed",
    },
  },
}

const overrides = {
  styles,
  colors,
  components: {},
}

export default extendTheme(overrides)
