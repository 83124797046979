import { Box } from '@chakra-ui/react'

const ProductDetailsView: React.FC<{description: string}> = ({description}) => {
    return (
        <>
            <Box mt="15px" fontSize="sm">
                { description }
            </Box>
        </>
    )
}

export default ProductDetailsView 