import SuccessAnimation from '../components/Animation/SuccessAnimation'
import { Button, VStack } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import HeaderPortal from '../components/Layout/HeaderPortal'
import HeaderTitle from '../components/Layout/HeaderTitle'
import ContentCard from '../components/Layout/ContentCard'
import ContentContainer from '../components/Layout/ContentContainer'

const Success = () => {
  const history = useHistory()
  return (
    <>
      <HeaderPortal>
        <HeaderTitle title='Vielen Dank für Ihre Bestellung' />
      </HeaderPortal>
      <ContentContainer>
        <ContentCard>
          <VStack spacing={4}>
            <SuccessAnimation />
            <Button
              variant=''
              size='md'
              color='white'
              bg='primary'
              _hover={{color: 'primary', bg: 'gray.100'}}
              fontWeight='bold'
              onClick={() => history.push('/')}
            >
              Zur Produktübersicht
            </Button>
          </VStack>
        </ContentCard>
      </ContentContainer>
    </>
  )
}

export default Success
