import { Button, Input, Stack, Grid, Box } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import BaseFormField from './BaseFormField'
import { useHistory } from 'react-router-dom'
import ContentCard from '../Layout/ContentCard'
import ContentContainer from '../Layout/ContentContainer'
import { getAddress, setAddress } from '../../services/AddressService'
import { AddressInput } from '../../schema/globalTypes'

const CheckoutForm = () => {
  const defaultValues = getAddress()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressInput>({ defaultValues })

  const history = useHistory()

  const onSubmit = (data: AddressInput) => {
    setAddress(data)
    history.push('/warenkorb/summary')
  }

  return (
    <Box minH='full'>
      <ContentContainer>
        <ContentCard>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={5} ml={4} mr={4}>
              <BaseFormField name='name' errors={errors}>
                <Input
                  placeholder='Name'
                  {...register('name', { required: 'Gib deinen Name ein.' })}
                />
              </BaseFormField>

              <BaseFormField name='addressLine1' errors={errors}>
                <Input
                  placeholder='Straße und Hausnummer'
                  {...register('addressLine1', {
                    required: 'Gib deinen Straße und Hausnummer ein.',
                  })}
                />
              </BaseFormField>

              <BaseFormField name='addressLine2' errors={errors}>
                <Input
                  placeholder='Wohnung, Zimmer, Nummer (optional)'
                  {...register('addressLine2')}
                />
              </BaseFormField>

              <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                <Box w='100%'>
                  <BaseFormField name='postcode' errors={errors}>
                    <Input
                      placeholder='PLZ'
                      {...register('postcode', {
                        required: 'Gib die Postleitzahl ein.',
                      })}
                    />
                  </BaseFormField>
                </Box>
                <Box w='100%'>
                  <BaseFormField name='city' errors={errors}>
                    <Input
                      placeholder='Ort'
                      {...register('city', {
                        required: 'Gib deine Stadt ein.',
                      })}
                    />
                  </BaseFormField>
                </Box>
              </Grid>

              <BaseFormField name='email' errors={errors}>
                <Input
                  placeholder='E-Mail'
                  {...register('email', {
                    required: 'Gib deinen E-Mail-Adresse ein.',
                  })}
                />
              </BaseFormField>

              <Button
                variant=''
                size='md'
                color='white'
                bg='primary'
                _hover={{color: 'primary', bg: 'gray.100'}}
                fontWeight='bold'
                w='full'
                type='submit'
              >
                Bestellung prüfen
              </Button>
            </Stack>
          </form>
        </ContentCard>
      </ContentContainer>
    </Box>
  )
}

export default CheckoutForm
