import { Box, Heading, Stack, Text } from '@chakra-ui/react'
import AddressCard from '../components/Summary/AddressCard'
import SummaryProductsList from '../components/Summary/SummaryProductsList'
import ContentContainer from '../components/Layout/ContentContainer'
import ContentCard from '../components/Layout/ContentCard'
import HeaderTitle from '../components/Layout/HeaderTitle'
import HeaderPortal from '../components/Layout/HeaderPortal'
import { Link } from 'react-router-dom'

const Summary = () => {
  return (
    <>
      <HeaderPortal>
        <HeaderTitle title='Bestellung absenden' />
      </HeaderPortal>
      <ContentContainer>
        <ContentCard>
          <Stack spacing='5'>
            <AddressCard />
            <Box>
              <Heading size='md' fontWeight='semibold'>
                Artikel
              </Heading>
              <Link to='/warenkorb'>
                <Text
                  fontSize='sm'
                  color='black'
                  _hover={{ color: 'gray.500' }}
                  mb={3}
                >
                  ändern
                </Text>
              </Link>
              <SummaryProductsList />
            </Box>
          </Stack>
        </ContentCard>
      </ContentContainer>
    </>
  )
}

export default Summary
