import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure } from '@chakra-ui/react'
import UpdateCategoryForm from './UpdateCategoryForm'

interface UpdateCategoryProps {
    id: string,
    name: string
}

const UpdateCategory = ({ id, name }: UpdateCategoryProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Button size="sm" onClick={onOpen}>bearbeiten</Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>bearbeiten</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <UpdateCategoryForm id={id} currentCategoryName={name} onClose={() => onClose()}/>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default UpdateCategory