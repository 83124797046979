import FadeInOut from '../components/Animation/FadeInOut'
import BackgroundPattern from '../components/Layout/BackgroundPattern'
import Checkout from './Checkout'
import { Box } from '@chakra-ui/react'

const CheckoutPage = () => (
  <>
    <BackgroundPattern />
    <FadeInOut>
      <Box mt="160px">
        <Checkout />
      </Box>
    </FadeInOut>
  </>
)

export default CheckoutPage
