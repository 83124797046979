import { Input, Button, VStack } from '@chakra-ui/react'
import { gql, useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import BaseFormField from '../../Checkout/BaseFormField'
import { CreateCategory } from '../../../schema/CreateCategory'

interface CreateCategoryFormProps {
    onToggle: () => void
}

interface AdminCreateCategory {
    name: string 
}

const CREATE_CATEGORY = gql`
  mutation CreateCategory($category: CategoryCreate!) {
    createCategory(category: $category) {
      id
      name
    }
  }
`

const CreateCategoryForm = ({ onToggle } : CreateCategoryFormProps) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm<AdminCreateCategory>()

    const [createCategoryMutation, { loading, error, data }] =
    useMutation<CreateCategory>(CREATE_CATEGORY, {
      update(cache, { data }) {
        cache.modify({
          fields: {
            categories(existingCategories = []) {
              if (data?.createCategory) {
                return [...existingCategories, data.createCategory]
              } else {
                return existingCategories
              }
            },
          },
        })
      },
    })

    const onSubmit = async (category: AdminCreateCategory) => {
        try {
            await createCategoryMutation({
              variables: { category: { name: category.name } },
            })
            reset()
            onToggle()
          } catch (error) {
            alert(error)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <VStack>
                    <BaseFormField name='name' errors={errors}>
                        <Input
                            placeholder='Kategorien'
                            {...register('name', { required: 'Gib deinen Kategorien ein.' })}
                        />
                    </BaseFormField>

                    <Button size="sm" variant="outline" colorScheme="green" w="full" type='submit'>Add</Button>
                </VStack>
            </form>
        </>
    )
}

export default CreateCategoryForm 
