import { Box } from '@chakra-ui/react'
import AdminProduct from '../components/Admin/AdminProduct/AdminProduct'

const AdminCategoryPage = () => {
    return (
        <>
            <Box mt='160px'>
                <AdminProduct/>
            </Box>
        </>
    )
}

export default AdminCategoryPage